import { Ref, ref } from 'vue'
import { HeaderSearchResultEmitType } from '@/Components/the-header-search-result/enums/HeaderSearchResultEmitType'
import { RoomType } from '@/Pages/the-home/enums/Search'

export const showCriteriaSearchModal = ref()

export const showLocationAndScrollTop = (): void => {
    showAndScrollTo(HeaderSearchResultEmitType.LOCATION)
}

export const showDatesAndScrollTop = (): void => {
    showAndScrollTo(HeaderSearchResultEmitType.DATES)
}

let scrollHandler: () => void

const showAndScrollTo = (position: HeaderSearchResultEmitType): void => {
    if (window.scrollY === 0) {
        handleShowCriteriaSearchModal(position)
    } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        scrollHandler = checkIfScrolledToTop(position)
        window.addEventListener('scroll', scrollHandler)
    }
}

export const handleShowCriteriaSearchModal = (searchCriteria: HeaderSearchResultEmitType): void => {
    // set to null that works watch() if click few time on same search Criteria
    showCriteriaSearchModal.value = null
    showCriteriaSearchModal.value = searchCriteria
}

export const selectedRoomType: Ref<RoomType | string> = ref('')

export const setSelectedRoomType = (isDorm: boolean): void => {
    if (isDorm) {
        selectedRoomType.value = RoomType.PRIVATE
    } else {
        selectedRoomType.value = RoomType.DORM
    }
}

const checkIfScrolledToTop = (position: HeaderSearchResultEmitType): () => void => {
    return () => {
        if (window.scrollY === 0) {
            handleShowCriteriaSearchModal(position)

            window.removeEventListener('scroll', scrollHandler)
        }
    }
}